import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import React, { useMemo, useState, useEffect } from "react";
import Title from "../components/Title";
import { makeRequest } from "../makeRequest";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Switch,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const preprocessEvents = (data) => {
  return data.map((item) => ({
    _id: item?._id,
    title: item?._id,
    members: item?.members,
    maxMembers: item?.maxMembers,
    start: new Date(item?.start),
    end: new Date(item?.end),
    startDate: new Date(item?.start).toISOString().split("T")[0],
    endDate: new Date(item?.end).toISOString().split("T")[0],
    startTime: new Date(item?.start).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    endTime: new Date(item?.end).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  }));
};

const groupEventsByDate = (data) => {
  const grouped = preprocessEvents(data).reduce((acc, item) => {
    const start = item?.startDate; // Format date as yyyy-MM-dd
    if (!acc[start]) {
      acc[start] = { start: start, events: [] };
    }
    acc[start].events.push(item);
    return acc;
  }, {});

  return Object.values(grouped);
};

const isRegistrationAllowed = (startTime) => {
  const now = new Date();
  const start = new Date(startTime);
  const diff = (start - now) / (1000 * 60 * 60); // difference in hours
  return diff >= 2;
};

// Process the data

function Cours() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [client, setClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      await makeRequest.get("/cours").then((res) => {
        //console.log(groupEventsByDate(res.data));
        setEvents(groupEventsByDate(res.data));
        setLoading(false);
      }); // Adjust the endpoint as needed
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const savedData = Cookies.get("rememberMe");
    if (savedData) {
      setRememberMe(true);
      setClient(JSON.parse(savedData));
    }

    fetchData();
  }, []);

  // const groupedData = useMemo(() => , [data]);
  // const events = useMemo(() => groupedData, [data]);

  const fullCalendarProps = useMemo(
    () => ({
      plugins: [dayGridPlugin],
      initialView: "dayGridMonth",
      defaultAllDay: true,
      height: "auto",

      validRange: {
        start: new Date(),
      },
      expandRows: true,
      eventClick: (e) => {
        handleOpen(e.event);
      },
      events: {
        events,
        color: "black",
        borderColor: "white",
        width: "100px",
      },
    }),
    [events]
  );

  const handleOpen = (event) => {
    setSelectedDate(event);
    const element = document.getElementById("cours");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  function renderEventContent(eventInfo) {
    return (
      <>
        <div
          className={`cours ${
            eventInfo?.event.start.getTime() === selectedDate?.start?.getTime()
              ? "bg-black"
              : "bg-stone-300"
          } p-2 duration-200 py-8  rounded m-1 text-center cursor-pointer border-2 border-black  flex items-center flex-col gap-1 justify-center`}
        >
          {eventInfo?.event.extendedProps.events.map((item) => (
            <p
              className="text-nowrap bg-white border-2 border-black text-black p-1 px-2 rounded-full"
              key={item._id}
            >
              {" "}
              {item?.startTime} - {item?.endTime}
            </p>
          ))}
        </div>
      </>
    );
  }

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const postRegister = async (id) => {
    const formData = { ...client, id: id };
    await makeRequest
      .post("/registrations", { formData })
      .then(async (response) => {
        setRegistration(response.data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleConfirm = async (id) => {
    const savedPassword = Cookies.get("coursKey");
    try {
      await makeRequest
        .post("/configs/verify/password", {
          value: decodeURIComponent(savedPassword),
        })
        .then((res) => {
          if (res.data) {
            setShowModal(false);
            postRegister(id);
            setOpen(true);
          } else {
            Cookies.remove("coursKey");
            alert("Accès refusé. Mot de passe invalide.");
            navigate("/Cours");
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Erreur lors de la vérification du mot de passe");
      setLoading(false);
    }
    if (rememberMe) {
      Cookies.set("rememberMe", JSON.stringify(client), { expires: 365 });
    } else {
      Cookies.remove("rememberMe");
    }
  };

  const closeModal = (id) => {
    setShowModal(false);
    if (rememberMe) {
      Cookies.set("rememberMe", JSON.stringify(client), { expires: 365 });
    } else {
      Cookies.remove("rememberMe");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36  flex flex-col items-center">
      {registration && (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={5000}
        >
          <Alert
            severity={registration?.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {registration?.msg}
          </Alert>
        </Snackbar>
      )}

      <div className=" flex justify-center items-center">
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modal-content flex items-center flex-col">
              <label>
                <Switch
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                Se souvenir de moi
              </label>
              <h2 className="excelFont text-3xl">Confirmer l'inscription</h2>

              <div className="flex mt-4 justify-evenly w-full poppins">
                {" "}
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleConfirm(selectedID)}
                >
                  Confirmer
                </Button>
                <Button variant="contained" color="error" onClick={closeModal}>
                  Annuler
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="md:max-w-[80%] fade-up sm:max-w-[95%] flex flex-col items-center">
        {" "}
        <div className="my-16">
          <Title word="Nos Cours" />
        </div>
        {loading ? (
          <CircularProgress> </CircularProgress>
        ) : (
          <div className="w-full flex flex-col">
            <div className="p-4 bg-white w-full excelFont text-2xl text-center my-4 xs-shadow">
              Selectionnez une date
              <p className="m-2 p-4 bg-black text-white poppins text-sm">
                Important: L’inscription et l’annulation à un cours doivent être
                effectuées au minimum 2 heures avant l’heure de début prévue.
                Aucune demande ne sera prise en compte après ce délai.
              </p>
            </div>
            <div className="w-full h-fit sm:p-4 lg:p-0 ">
              <FullCalendar
                eventContent={renderEventContent}
                {...fullCalendarProps}
              />
            </div>
            <div className="w-full mt-12">
              {selectedDate && (
                <div className="p-4 fade-up bg-white w-full excelFont text-2xl text-center my-4 xs-shadow ">
                  Selectionnez un cours
                </div>
              )}

              <div id="cours">
                <div className="flex justify-center my-12">
                  {selectedDate?.extendedProps?.events && (
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                      {selectedDate?.extendedProps?.events?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="w-60 h-60 fade-up excelFont text-3xl border-4 sm-shadow bg-white  border-[#111] rounded-full flex flex-col justify-center items-center"
                          >
                            <p className="excelFont">
                              {item?.startTime} - {item?.endTime}
                            </p>

                            <p className="excelFont text-4xl">
                              [ {item?.members.length} / {item?.maxMembers}]
                            </p>
                            <div className="w-fit h-fit relative mt-4">
                              <img
                                className="absolute w-14 -left-16 z-10 top-1/2 -translate-y-2/3 sm-shadow"
                                src={`${process.env.PUBLIC_URL}/assets/muscle.png`}
                              ></img>
                              <img
                                className="absolute scale-x-[-1] w-14 -right-16 z-10 top-1/2 -translate-y-2/3 sm-shadow"
                                src={`${process.env.PUBLIC_URL}/assets/muscle.png`}
                              ></img>
                              <div
                                className={`${
                                  (item?.members.length === item?.maxMembers ||
                                    !isRegistrationAllowed(item?.start)) &&
                                  "pointer-events-none "
                                } bouncy-transition hover:scale-110`}
                              >
                                <a
                                  href={`/Cours/${item?._id}`}
                                  onClick={(e) => {
                                    rememberMe && e.preventDefault();
                                    rememberMe && setShowModal(true);
                                    rememberMe && setSelectedID(item?._id);
                                  }}
                                  className={`${
                                    (item?.members.length ===
                                      item?.maxMembers ||
                                      !isRegistrationAllowed(item?.start)) &&
                                    "pointer-events-none bg-opacity-30"
                                  } bg-black relative text-white title border-4 border-black select-none cursor-pointer fade-in xs-shadow  p-2  rounded-xl text-4xl z-40`}
                                  disabled={
                                    item?.members.length === item?.maxMembers ||
                                    !isRegistrationAllowed(item?.start)
                                  }
                                >
                                  S'inscrire
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </div>
    </div>
  );
}

export default Cours;
