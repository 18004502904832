import React, { useEffect, useState } from "react";
import Home from "./pages/Home";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { makeRequest } from "./makeRequest";
import EmojiLogo from "./components/EmojiLogo";
import LogoAnimation from "./components/LogoAnimation";
import About from "./pages/About";
import Cours from "./pages/Cours";
import FAQ from "./pages/FAQ";
import Forfaits from "./pages/Forfaits";
import Register from "./pages/Register";
import Pricing from "./components/Pricing";
import Login from "./pages/Login";
import Coursmanager from "./admin/Coursmanager";
import Usersmanager from "./admin/Usersmanager";
import CreateClient from "./admin/CreateClient";
import CancelRegistration from "./pages/CancelRegistrations";
import ClientsManager from "./admin/ClientsManager";
import RegistrationsManager from "./admin/RegistrationsManager";
import FAQManager from "./admin/FAQManager";
import TrainersManager from "./admin/TrainersManager";
import "react-quill/dist/quill.snow.css";
import { Alert, Snackbar } from "@mui/material";
import TarifsManager from "./admin/TarifsManager";
import ProtectedCours from "./pages/ProtectedCours";
import Cookies from "js-cookie";
import Photos from "./pages/Photos";
import ConfigsManager from "./admin/ConfigsManager";
import ClientRegistrations from "./admin/ClientRegistrations";

function App() {
  const [userConsent, setUserConsent] = useState(Cookies.get("userConsent"));

  useEffect(() => {
    if (userConsent !== "true") {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [userConsent]);

  return (
    <Router basename="/">
      <Nav />

      <LogoAnimation></LogoAnimation>

      <div
        className="w-screen h-screen fixed top-0 left-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/kisss-background.png)`,
        }}
      >
        <img
          className="xs-shadow absolute sm:bottom-12 lg:bottom-0 right-0  sm:w-[250px] lg:w-[500px]"
          src={`${process.env.PUBLIC_URL}/assets/kisss_rope.png`}
        ></img>
        <img
          className="xs-shadow absolute sm:bottom-2 lg:bottom-0 right-2  sm:w-[150px] lg:w-[200px]"
          src={`${process.env.PUBLIC_URL}/assets/kisss_dumbell.png`}
        ></img>

        <img
          className="xs-shadow absolute sm:bottom-8 lg:bottom-0 left-0 -translate-x-4 sm:w-[200px] lg:w-[300px]"
          src={`${process.env.PUBLIC_URL}/assets/kisss_kilobell.png`}
        ></img>
      </div>
      <div className="gradient w-full flex-col min-h-screen relative m-0 flex ">
        <Routes>
          <Route path="/" element={<Navigate to="/Home" />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Cours" element={<ProtectedCours />} />
          <Route path="/Cours/:id" element={<Register />} />
          <Route path="/Tarifs" element={<Forfaits />} />
          <Route path="/Faq" element={<FAQ />} />
          <Route path="/Photos" element={<Photos />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/ConfigsManager" element={<ConfigsManager />} />
          <Route path="/Coursmanager" element={<Coursmanager />} />
          <Route path="/ClientsManager" element={<ClientsManager />} />
          <Route path="/Client/:id" element={<ClientRegistrations />} />
          <Route path="/Usersmanager" element={<Usersmanager />} />
          <Route path="/CreateClient/:id" element={<CreateClient />} />
          <Route
            path="/RegistrationsManager/:id"
            element={<RegistrationsManager />}
          />
          <Route path="/FAQManager" element={<FAQManager />} />
          <Route path="/TrainersManager" element={<TrainersManager />} />
          <Route path="/TarifsManager" element={<TarifsManager />} />
          <Route
            path="/cancelRegistration/:id"
            element={<CancelRegistration />}
          />
        </Routes>
      </div>
      <Footer />

      {userConsent !== "true" && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.95)",
            zIndex: 9999,
          }}
        >
          <CookieConsent
            location="bottom"
            buttonText="Accepter"
            cookieName="userConsent"
            expires={365}
            style={{
              background: "#fff",
              color: "black",
              border: "4px solid black",
            }}
            buttonStyle={{
              background: "#4CAF50",
              color: "#fff",
              fontSize: "14px",
              padding: "10px 20px",
            }}
            onAccept={() => {
              console.log("Cookies acceptes");
              setUserConsent("true");
            }}
          >
            Ce site web utilise des cookies pour améliorer l'expérience
            utilisateur.{" "}
          </CookieConsent>
        </div>
      )}
    </Router>
  );
}

export default App;
