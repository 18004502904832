import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ color: [] }, { background: [] }],
    [{ align: [] }], // Add text and background color options
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "indent",
  "color", // Add support for text color
  "background",
  "align",
];

function TextEditor({ onValueChange, edit }) {
  const [value, setValue] = useState("");

  const handleInputChange = (e) => {
    const newValue = e;
    setValue(newValue);
    // Call the parent's callback function
    onValueChange(newValue);
  };

  useEffect(() => {
    setValue(edit);
    console.log(edit);
  }, [edit]);

  return (
    <div className="w-full">
      <ReactQuill
        theme="snow"
        onChange={handleInputChange}
        modules={modules}
        formats={formats}
        value={value}
      ></ReactQuill>
      {/* <div dangerouslySetInnerHTML={{ __html: value }}></div> */}
      {/* <div>{value}</div> */}
    </div>
  );
}

export default TextEditor;
