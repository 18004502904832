import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Cours from "./Cours";
import { makeRequest } from "../makeRequest";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedCours = () => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(true);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberComputer, setRememberComputer] = useState(false);
  const [verificationMessage, setVerificationMessage] =
    useState("Verification...");

  useEffect(() => {
    window.scrollTo(0, 0);
    const savedPassword = Cookies.get("coursKey");
    if (savedPassword) {
      setPassword(decodeURIComponent(savedPassword));
      verify(decodeURIComponent(savedPassword));
    }
  }, []);

  const handlePasswordSubmit = () => {
    verify(password);
  };

  const verify = async (passwordToVerify) => {
    setLoading(true);
    setVerificationMessage("Verification...");
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay of 2 seconds
      await makeRequest
        .post("/configs/verify/password", { value: passwordToVerify })
        .then((res) => {
          if (res.data) {
            setVerificationMessage("Bienvenue");
            setIsAuthenticated(res.data);
            setPasswordModalOpen(false);

            if (rememberComputer) {
              Cookies.set("coursKey", encodeURIComponent(passwordToVerify), {
                expires: 90,
              });
              Cookies.set("rememberComputer", true, { expires: 90 });
            } else {
              Cookies.set("coursKey", encodeURIComponent(passwordToVerify), {
                expires: 1,
              });
            }
          } else {
            const savedPassword = Cookies.get("coursKey");
            if (savedPassword) {
              setVerificationMessage("Mot de passe expiré");
              Cookies.remove("coursKey");
            } else {
              setVerificationMessage("Mot de passe invalide");
            }
          }
        }); // Adjust the endpoint as needed
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Erreur lors de la vérification du mot de passe");
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    gap: 2,
  };

  return (
    <>
      <Modal
        open={passwordModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="fade-up"
      >
        <Box sx={style}>
          <div className="bg-white border-4 rounded-lg border-black p-4 shadow-sm">
            <Link to={"/Home"} className="absolute p-4 top-0 right-0">
              <CloseIcon />
            </Link>
            {loading ? (
              <div className="flex flex-col items-center gap-2">
                <img
                  className="w-12 h-12 animate-bounce"
                  src={`${process.env.PUBLIC_URL}/assets/heart_eyes.png`}
                  alt="Loading"
                />
                <p>{verificationMessage}</p>
              </div>
            ) : (
              <>
                <h2 className="excelFont text-3xl w-full text-center">
                  Entrez le mot de passe
                </h2>
                <div className="modal-content flex items-center flex-col poppins">
                  <TextField
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label="Mot de passe"
                    variant="outlined"
                    margin="normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberComputer}
                        size=""
                        onChange={(e) => setRememberComputer(e.target.checked)}
                      />
                    }
                    label="Se souvenir de cet appareil (3 mois)"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePasswordSubmit}
                    size="large"
                    disabled={loading}
                  >
                    Soumettre
                  </Button>
                </div>
              </>
            )}
          </div>
          {!loading && (
            <div className="bg-white border-4 rounded-lg border-black p-4 poppins shadow-sm">
              <div className="mt-4 gap-2 text-center text-sm">
                <p>Vous n'êtes pas membre?</p>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  component={Link}
                  to="/About#team"
                >
                  Contactez-nous
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>

      {isAuthenticated && <Cours />}
    </>
  );
};

export default ProtectedCours;
