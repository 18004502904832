import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

function Trainer({ trainer }) {
  const [currentImage, setCurrentImage] = useState(trainer.image);
  const [isImage1, setIsImage1] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsImage1((prev) => !prev);
      if (trainer.image2) {
        setCurrentImage((prevImage) =>
          prevImage === trainer.image ? trainer.image2 : trainer.image
        );
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [trainer.image, trainer.image2]);

  return (
    <motion.div className="flex flex-col gap-8 items-center">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.3 }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
        variants={{
          hidden: { opacity: 0, transform: "translateY(50px)" },
          visible: {
            opacity: 1,
            transform: "translateY(0px)",
          },
        }}
        className="sm:w-96 lg:w-[500px] aspect-3/4"
      >
        <motion.img
          key={currentImage}
          draggable={false}
          src={currentImage}
          alt="Background"
          className="w-full h-full block object-cover rounded-3xl border-[16px] bg-white border-black sm-shadow"
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        />
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.1 }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
        variants={{
          hidden: { opacity: 0, transform: "translateY(50px)" },
          visible: {
            opacity: 1,
            transform: "translateY(0px)",
          },
        }}
        className="bg-white lg:w-2/3 sm:w-4/5 h-fit p-8 border-[16px] rounded-sm sm-shadow border-black"
      >
        <div className="lg:min-w-5/6 sm:min-w-5/6 min-h-fit h-fit">
          <h1 className="excelFont text-white text-center bg-black sm:p-2 rounded-full sm:text-4xl lg:text-5xl leading-6">
            {trainer.firstname + " " + trainer.lastname}
          </h1>
          <h2 className="bg-black m-2 sm:text-center sm:text-base font-extralight text-white rounded-md">
            {trainer.occupations.join(" - ")}
          </h2>

          <div className="flex h-2/5 justify-center items-center ql-snow">
            <p className="px-8 ql-editor">
              <div dangerouslySetInnerHTML={{ __html: trainer.message }}></div>
            </p>
          </div>
          <div className="flex gap-8 w-full h-1/5 items-center justify-center">
            {trainer.facebook !== "" && (
              <a href={trainer.facebook} className="text-black" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 50 50"
                >
                  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
            )}
            {trainer.instagram !== "" && (
              <a
                href={trainer.instagram}
                className="text-black"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 30 30"
                >
                  <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                </svg>
                <span className="sr-only">Instagram page</span>
              </a>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Trainer;
