import React, { useEffect, useState } from "react";
import CreateUserForm from "./components/CreateUserForm";
import Title from "../components/Title";
import { makeRequest } from "../makeRequest";
import EditUsernameForm from "./components/EditUserForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
import DeleteUserButton from "./components/DeleteUserButton";

function Usersmanager() {
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await makeRequest.get("/users/roles/users");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users", error);
      }
    };

    const fetchRole = async () => {
      try {
        const response = await makeRequest.get("/users/me");
        setRole(response.data.role);
      } catch (error) {
        console.error("Error fetching users", error);
      }
    };

    fetchUsers();
    fetchRole();
  }, []);

  return (
    <>
      {role !== "admin" ? (
        <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
          <Title word={"Admin Access"}></Title>
          <Title word={"Only"}></Title>
        </div>
      ) : (
        <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
          <div className="my-16">
            <Title word="Create Users" />
          </div>
          <div className="p-8 w-full poppins">
            <CreateUserForm></CreateUserForm>
          </div>
          <div className="my-16">
            <Title word="Users" />
          </div>
          <div>
            <ul className="flex flex-col gap-4">
              {users?.map((user) => (
                <li
                  className="flex poppins gap-4 p-8  lg:w-full h-fit  flex-col bg-white border-[16px] border-black rounded sm:w-full  mx-auto sm-shadow"
                  key={user?._id}
                >
                  {user?.username} - {user?.email}
                  <EditUsernameForm userId={user?._id} />
                  <ChangePasswordForm userId={user?._id} />
                  <DeleteUserButton userId={user?._id} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Usersmanager;
