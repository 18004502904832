import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { delay, motion } from "framer-motion";
import Title from "../components/Title";
import { makeRequest } from "../makeRequest";

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative transition  hover:bg-indigo-50 rounded">
      {/* Header */}
      <div
        className="accordion-header cursor-pointer ease-out flex space-x-5 px-5 items-center h-16"
        onClick={toggleAccordion}
      >
        {isOpen ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        <h3>{title}</h3>
      </div>

      {/* Content */}
      <div
        className={`accordion-content ease-in-out px-5 pt-0 overflow-hidden border-b-2`}
        style={{
          maxHeight: isOpen ? "200px" : "0",
          transition: "max-height  0.3s ease-in-out",
        }}
      >
        <div className=" ql-snow">
          <p className="px-8 ql-editor">
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
};

const Accordion = () => {
  const location = useLocation();
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await makeRequest.get("/faqs");
        setFaqs(response.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="FAQ" />
      </div>
      <div className="lg:w-3/4 sm: sm:w-full mx-auto sm-shadow">
        <div className="bg-white p-10 shadow-sm rounded relative">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: "all" }}
            transition={{
              duration: 0.3,
              ease: [0, 0.71, 0.2, 1.01],
              scale: {
                delay: 0.5,
                type: "spring",
                damping: 5,
                stiffness: 100,
                restDelta: 0.001,
              },
            }}
            variants={{
              hidden: { opacity: 0, scale: 0 },
              visible: {
                opacity: 1,
                scale: 0.5,
                translate: "50% -50%",
                rotate: "12deg",
                zIndex: 20,
              },
            }}
            className="absolute top-0  sm:right-12 lg:right-32 sm:w-40 translate-x-1/3 -translate-y-1/3 xs-shadow"
            src={`${process.env.PUBLIC_URL}/assets/thinking_face.png`}
          ></motion.img>
          <h3 className="text-lg font-medium text-gray-800">
            Certaines de nos questions fréquemment posées.
          </h3>

          <div className="h-1 w-full mx-auto border-b my-5"></div>

          {faqs?.map((item, index) => (
            <AccordionItem
              key={index}
              title={item?.question}
              content={item?.answer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
