import "./styles.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Title from "./Title";
import { makeRequest } from "../makeRequest";

function Pricing() {
  const location = useLocation();
  const [tarifs, setTarifs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  useEffect(() => {
    const fetchTarifs = async () => {
      try {
        const response = await makeRequest.get("/tarifs");
        setTarifs(response.data);
        setSelectedTab(response.data[0]);
      } catch (error) {
        console.error("Error fetching tarifs:", error);
      }
    };

    fetchTarifs();
  }, []);

  return (
    <div className="min-h-fit fade-up flex flex-col items-center">
      <div className="mb-16">
        <Title word="Nos Tarifs" />
      </div>
      <div className="window border-8 border-black sm:h-[500px] lg:h-[500px] w-[500px] rounded sm-shadow">
        <nav>
          <ul className="grid sm:grid-cols-2 md:grid-cols-3 w-full">
            {tarifs?.map((item, index) => (
              <li
                key={item?.title}
                className={item === selectedTab ? "selected w-full" : ""}
                onClick={() => setSelectedTab(tarifs[index])}
              >
                <p className="whitespace-nowrap w-full excelFont text-center text-3xl">
                  {item?.title}
                </p>
                {item?.title === selectedTab?.title ? (
                  <motion.div className="underline z-10" layoutId="underline" />
                ) : null}
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex-1 relative w-full h-full">
          <AnimatePresence mode="wait">
            <motion.div
              className="flex flex-1 relative w-full h-full"
              key={selectedTab ? selectedTab.title : "empty"}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              {selectedTab ? (
                <div className="w-full h-full flex flex-col justify-center p-8">
                  <div className="flex justify-center items-center ql-snow">
                    <p className="px-8 ql-editor">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedTab.description,
                        }}
                      ></div>
                    </p>
                  </div>
                  <AnimatePresence mode="wait"></AnimatePresence>
                </div>
              ) : (
                "😋"
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
