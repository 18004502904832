import React, { useState, useEffect } from "react";
import { CardMedia } from "@mui/material";

const TrainerCard = ({ trainer }) => {
  const [currentImage, setCurrentImage] = useState(trainer.image);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === trainer.image ? trainer.image2 : trainer.image
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [trainer.image, trainer.image2]);

  return (
    <CardMedia
      component="img"
      height="200"
      image={currentImage} // Switch between image and image2
      alt={trainer.firstname}
    />
  );
};

export default TrainerCard;
