import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { delay, motion } from "framer-motion";
import Pricing from "../components/Pricing";
import Title from "../components/Title";
import { Link } from "react-router-dom";

const Home = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);

  useEffect(() => {
    const updateCustomProperties = () => {
      const width = window.innerWidth;

      // Update properties for Slider 1

      // Update properties for Slider 2
      const slider2 = document.getElementById("slider-2");
      if (slider2) {
        slider2.style.setProperty(
          "--width",
          width > 768 ? "400px" : width > 480 ? "300px" : "300px"
        );
        slider2.style.setProperty(
          "--height",
          width > 768 ? "400px" : width > 480 ? "300px" : "300px"
        );
        slider2.style.setProperty("--quantity", "5");
      }
    };

    window.addEventListener("resize", updateCustomProperties);
    updateCustomProperties(); // Initial call

    return () => window.removeEventListener("resize", updateCustomProperties);
  }, []);

  return (
    <div className="w-full h-full relative overflow-hidden">
      <div className="w-screen h-screen overflow-hidden absolute top-0 left-0">
        {" "}
        <video
          id="pageBackground_k42v9_video"
          class="K8MSra"
          crossorigin="anonymous"
          playsinline=""
          preload="auto"
          muted="true"
          loop="true"
          tabindex="-1"
          autoplay=""
          src={`${process.env.PUBLIC_URL}/assets/VID_20250303_225727.mp4`}
          className="h-full w-full object-cover object-center"
          // style="height: 100%; width: 100%; object-fit: cover; object-position: center center; opacity: 1;"
        ></video>
      </div>

      <div></div>

      <div className="relative  z-0 bg-[#111] max-h-screen bg-opacity-40">
        {" "}
        <div className="w-full h-screen flex items-center justify-center bg-gradient-to-t from-[#111] to-transparent text-white">
          <div className="w-1/2"></div>
          <div className="w-1/2 fade-in flex items-center flex-col text-right sm:hidden lg:block lg:mr-56">
            {" "}
            <p className="title text-9xl leading-[4rem] xs-shadow mr-4">
              KEEP IT SUPER{" "}
            </p>
            <p className="title text-9xl leading-[4rem] xs-shadow"> SIMPLE </p>
            <p className="title text-9xl leading-[4rem] xs-shadow"> & STUPID</p>
            <Link to="/Cours">
              <button className="bg-slate-800 title fade-in hover:bg-slate-100 hover:scale-110 hover:bg-opacity-20 duration-200 bg-opacity-30 p-4 m-8 border-2 rounded-xl text-4xl z-40">
                PARTICIPER!
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col title min-h-screen  items-center justify-center fade-up">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          className="w-full flex flex-col title min-h-screen items-center justify-center"
        >
          {" "}
          <div className="my-16">
            <Title word="Nous Offrons" />
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-20">
            <div className="w-60 h-60  border-4 sm-shadow bg-white  border-[#111] rounded-full flex  justify-center items-center">
              <motion.img
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: "all" }}
                transition={{
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
                  scale: {
                    delay: 0.5,
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001,
                  },
                }}
                variants={{
                  hidden: { opacity: 0, scale: 0 },
                  visible: {
                    opacity: 1,
                    scale: 0.5,
                    translate: "-50% -50%",
                    rotate: "-12deg",
                    zIndex: 20,
                  },
                }}
                className="absolute top-0  left-0 w-2/3 translate-x-1/3 -translate-y-1/3 xxs-shadow"
                src={`${process.env.PUBLIC_URL}/assets/relieved.png`}
              ></motion.img>
              <h3 className=" text-center  excelFont  text-4xl xxxs-shadow -rotate-12 border-white rounded-3xl p-8">
                Programmes personnalisés et coaching
              </h3>{" "}
            </div>
            <div className="w-60 h-60  border-4 sm-shadow bg-white  border-[#111] rounded-full flex  justify-center items-center">
              <h3 className=" text-center text-balance excelFont text-5xl xxxs-shadow -rotate-12 border-white rounded-3xl p-8">
                Suivi des progrès et défis
              </h3>{" "}
            </div>{" "}
            <div className="w-60 h-60 relative  border-4 sm-shadow bg-white  border-[#111] rounded-full flex  justify-center items-center">
              <motion.img
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: "all" }}
                transition={{
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
                  scale: {
                    delay: 0.5,
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001,
                  },
                }}
                variants={{
                  hidden: { opacity: 0, scale: 0 },
                  visible: {
                    opacity: 1,
                    scale: 0.5,
                    translate: "50% -50%",
                    rotate: "12deg",
                    zIndex: 20,
                  },
                }}
                className="absolute top-0  right-0 w-2/3 translate-x-1/3 -translate-y-1/3 xxs-shadow"
                src={`${process.env.PUBLIC_URL}/assets/smiling_imp.png`}
              ></motion.img>
              <h3 className=" text-center excelFont text-7xl xxs-shadow -rotate-12 border-white rounded-3xl p-8">
                INTENSITE
              </h3>{" "}
            </div>{" "}
            <div className="w-60 h-60  border-4 sm-shadow bg-white  border-[#111] rounded-full flex  justify-center items-center">
              <h3 className=" text-center excelFont text-4xl xxxs-shadow -rotate-12 border-white rounded-3xl p-8">
                Ateliers et événements exclusifs
              </h3>{" "}
            </div>
            <div className="w-60 h-60  border-4 sm-shadow bg-white  border-[#111] rounded-full flex  justify-center items-center">
              <motion.img
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: "all" }}
                transition={{
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
                  scale: {
                    delay: 0.5,
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001,
                  },
                }}
                variants={{
                  hidden: { opacity: 0, scale: 0 },
                  visible: {
                    opacity: 1,
                    scale: 0.5,
                    translate: "50% -50%",
                    rotate: "12deg",
                    zIndex: 20,
                  },
                }}
                className="absolute top-0  right-0 w-2/3 translate-x-1/3 -translate-y-1/3 xxs-shadow"
                src={`${process.env.PUBLIC_URL}/assets/heart_eyes.png`}
              ></motion.img>
              <h3 className=" text-center excelFont text-4xl xxxs-shadow -rotate-12 border-white rounded-3xl p-8">
                Entraînements de groupe motivants
              </h3>{" "}
            </div>
            <div className="w-60 h-60  border-4  sm-shadow bg-white  border-[#111] rounded-full flex  justify-center items-center">
              <h3 className=" text-center excelFont text-4xl xxxs-shadow -rotate-12 border-white rounded-3xl p-8">
                Programmes de progression structurés
              </h3>{" "}
            </div>
          </div>
        </motion.div>
        <div className="w-full h-screen   flex items-center justify-center">
          <Pricing></Pricing>
        </div>
        <div className="w-full flex flex-col title min-h-screen items-center sm:mb-24  justify-center">
          <div className="mb-16">
            <Title word="Nos Athletes" />
          </div>
          <div className="w-fit h-fit relative">
            <span className="w-full h-2 absolute bottom-0 sm-shadow bg-black bg-opacity-45 "></span>
            <main className="">
              <div
                className="slider bg-white border-t-8 border-black"
                style={{
                  "--width": "100px",
                  "--height": "100px",
                  "--quantity": "9",
                }}
              >
                <div className="list">
                  <div className="item" style={{ "--position": "1" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/arm.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "2" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/heart_eyes.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "3" }}>
                    <img
                      className="-scale-x-100"
                      src={`${process.env.PUBLIC_URL}/assets/arm.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "4" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/arm.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "5" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/triumph.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "6" }}>
                    <img
                      className="-scale-x-100"
                      src={`${process.env.PUBLIC_URL}/assets/arm.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "7" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/arm.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "8" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/relieved.png`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "9" }}>
                    <img
                      className="-scale-x-100"
                      src={`${process.env.PUBLIC_URL}/assets/arm.png`}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                id="slider-2"
                className="slider pt-4 border-b-8 border-black bg-white sm-shadow"
                reverse="true"
                style={{
                  "--width": "400px",
                  "--height": "400px",
                  "--quantity": "5",
                }}
              >
                <div className="list">
                  <div className="item" style={{ "--position": "1" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/thumbnail_IMG_2218.jpg`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "2" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/IMG_14232.jpeg`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "3" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/IMG_14233.jpeg`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "4" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/IMG_14231.jpeg`}
                      alt=""
                    />
                  </div>
                  <div className="item" style={{ "--position": "5" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/IMG_14234.jpeg`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
