import React, { useState } from "react";
import {
  TextField,
  Button,
  Chip,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeRequest } from "../../makeRequest";
import TextEditor from "./TextEditor";
import { Label } from "@mui/icons-material";

const TrainerForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    message: "",
    occupations: [],
    instagram: "",
    facebook: "",
    image1: "", // Image Key from backend
    image2: "", // Image Key from backend
  });
  const [preview, setPreview] = useState(null); // For image preview
  const [preview2, setPreview2] = useState(null); // For second image preview
  const [isUploading, setIsUploading] = useState(false); // For upload loader
  const [image1, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [value, setValue] = useState("");
  const [valueFromChild, setValueFromChild] = useState("");
  const [textEditor, setTextEditor] = useState("");
  const [occupation, setOccupation] = useState("");

  const handleValueChange = (newValue) => {
    setValueFromChild(newValue);
    setFormData({ ...formData, message: newValue });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddOccupation = (occupation) => {
    setFormData({
      ...formData,
      occupations: [...formData.occupations, occupation],
    });
  };

  const handleRemoveOccupation = (index) => {
    setFormData({
      ...formData,
      occupations: formData.occupations.filter((_, i) => i !== index),
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Show the preview
    const reader = new FileReader();
    reader.onloadend = () => setPreview(reader.result);
    reader.readAsDataURL(file);

    // Upload the image to the /image route

    const data = new FormData();
    data.append("image", file);
    setImage(data);
  };

  const handleFile2Change = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Show the preview
    const reader = new FileReader();
    reader.onloadend = () => setPreview2(reader.result);
    reader.readAsDataURL(file);

    // Upload the image to the /image route

    const data = new FormData();
    data.append("image", file);
    setImage2(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsUploading(true);
    try {
      const response = await makeRequest.post("/images/trainer", image1, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const response2 = await makeRequest.post("/images/trainer", image2, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const key = response.data.name;
      const key2 = response2.data.name;
      try {
        const response = await makeRequest.post("/trainers", {
          formData,
          image: key,
          image2: key2,
        });
        console.log("Trainer created:", response.data);
      } catch (err) {
        console.error("Error creating trainer:", err);
      }
    } catch (err) {
      console.error("Error uploading image:", err);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4 }} className="poppins">
      <Typography variant="h5" gutterBottom>
        Add Trainer
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="First Name"
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Last Name"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          required
        />
        <p>Message</p>
        <div className="bg-white">
          <TextEditor onValueChange={handleValueChange}></TextEditor>
        </div>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 2 }}></Box>
        <div className="flex justify-between gap-2 h-fit">
          <TextField
            fullWidth
            margin="normal"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            label="Add Occupation"
          />
          <Button
            onClick={() => {
              handleAddOccupation(occupation);
              setOccupation("");
            }}
            variant="contained"
          >
            Ajouter{" "}
          </Button>
        </div>
        {formData.occupations.map((occ, index) => (
          <Chip
            key={index}
            label={occ}
            onDelete={() => handleRemoveOccupation(index)}
          />
        ))}
        <TextField
          fullWidth
          margin="normal"
          label="Instagram"
          name="instagram"
          value={formData.instagram}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Facebook"
          name="facebook"
          value={formData.facebook}
          onChange={handleChange}
        />
        <Button variant="contained" component="label" sx={{ mt: 2, mb: 2 }}>
          Upload Image
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleFileChange}
          />
        </Button>
        {isUploading && <CircularProgress size={24} />}
        {preview && (
          <Box
            component="img"
            src={preview}
            alt="Preview"
            sx={{ width: "100%", height: "auto", mb: 2 }}
          />
        )}
        <Button variant="contained" component="label" sx={{ mt: 2, mb: 2 }}>
          Upload Image 2
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleFile2Change}
          />
        </Button>
        {isUploading && <CircularProgress size={24} />}
        {preview2 && (
          <Box
            component="img"
            src={preview2}
            alt="Preview"
            sx={{ width: "100%", height: "auto", mb: 2 }}
          />
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!image1 || !image2} // Disable until both images are uploaded
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default TrainerForm;
