import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Coursmanager = () => {
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    maxMembers: "",
  });
  const [filters, setFilters] = useState({
    start: dayjs().format("YYYY-MM-DD"), // Today's date
    end: null,
  });
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Fetch Current User
  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setUser(response.data);
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  // Fetch Courses with Filters
  const fetchCourses = async () => {
    try {
      const response = await makeRequest.get("/cours", {
        params: filters,
      });
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isAuthorized) fetchCourses();
  }, [isAuthorized]);

  // Handle Dialog Open/Close
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditing(false);
    setFormData({ startDate: null, startTime: null, endDate: null, endTime: null, maxMembers: "" });
  };

  // Handle Input Change
  const handleChange = (name, value) => {
    if (name === "startDate") {
      setFormData({ ...formData, startDate: value, endDate: value });
    } else if (name === "startTime") {
      setFormData({ ...formData, startTime: value, endTime: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    console.log(formData);
  };

  // Handle Filter Change
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Create or Update Course
  const handleSubmit = async () => {
    try {
      const start = dayjs(formData.startDate).format("YYYY-MM-DD") + "T" + dayjs(formData.startTime).format("HH:mm:ss");
      const end = dayjs(formData.endDate).format("YYYY-MM-DD") + "T" + dayjs(formData.endTime).format("HH:mm:ss");
      console.log(formData.startDate);
      const updatedData = {
        ...formData,
        start,
        end,
        createdBy: user.username,
        updatedBy: user.username,
      };

      if (editing) {
        await makeRequest.put(`/cours/${formData._id}`, updatedData);
      } else {
        await makeRequest.post("/cours", updatedData);
      }

      fetchCourses();
      handleClose();
    } catch (error) {
      console.error("Error saving course:", error.message);
    }
  };

  // Delete Course
  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this course?");
    if (!confirmed) return;
  
    try {
      await makeRequest.delete(`/cours/${id}`);
      fetchCourses();
    } catch (error) {
      console.error("Error deleting course:", error.message);
    }
  };

  // Open Dialog to Edit Course
  const handleEdit = (course) => {
    setEditing(true);
    setFormData({
      ...course,
      startDate: dayjs(course.start),
      startTime: dayjs(course.start),
      endDate: dayjs(course.end),
      endTime: dayjs(course.end),
    });
    handleOpen();
  };

  // Filter for Current Week
  const filterCurrentWeek = () => {
    const now = dayjs();
    const startOfWeek = now.startOf("week");
    const endOfWeek = now.endOf("week");
    setFilters({
      start: startOfWeek.format("YYYY-MM-DD"),
      end: endOfWeek.format("YYYY-MM-DD"),
    });
  };

  // Filter for Current Month
  const filterCurrentMonth = () => {
    const now = dayjs();
    const startOfMonth = now.startOf("month");
    const endOfMonth = now.endOf("month");
    setFilters({
      start: startOfMonth.format("YYYY-MM-DD"),
      end: endOfMonth.format("YYYY-MM-DD"),
    });
  };

  // Format Date
  const formatDate = (date) => {
    return format(new Date(date), "EEE d MMMM yyyy", { locale: fr });
  };

  if (!isAuthorized) {
    return (
      <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
        <Title word={"User Access"}></Title>
        <Title word={"Only"}></Title>
      </div>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center ">
        <div className="my-16">
          <Title word="Create Cours" />
        </div>
        {/* Filters */}
        <Box display="flex" className="poppins" gap={2} mb={3}>
          <TextField
            label="Start Date"
            name="start"
            type="date"
            value={filters.start}
            onChange={handleFilterChange}
          />
          <TextField
            label="End Date"
            focused
            name="end"
            type="date"
            value={filters.end}
            onChange={handleFilterChange}
          />
          <Button variant="contained" onClick={fetchCourses}>
            Apply Filters
          </Button>
          <Button variant="contained" onClick={filterCurrentWeek}>
            Current Week
          </Button>
          <Button variant="contained" onClick={filterCurrentMonth}>
            Current Month
          </Button>
        </Box>

        <Button className="poppins" variant="contained" startIcon={<Add />} onClick={handleOpen}>
          Add Course
        </Button>

        {/* Course Table */}
        <TableContainer className="poppins" component={Paper} sx={{ margin: 10 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>Start & End Time</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>Max Members</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses?.map((course) => (
                <TableRow key={course?._id}>
                  <TableCell>{formatDate(course?.start)}</TableCell>
                  <TableCell>
                    {dayjs(course?.start).format("HH:mm")} - {dayjs(course?.end).format("HH:mm")}
                  </TableCell>
                  <TableCell>
                    {course?.members.length > 0 ? (
                      <a
                        className="underline font-bold"
                        href={`/RegistrationsManager/${course?._id}`}
                      >
                        {course?.members.length}
                      </a>
                    ) : (
                      <p>{course?.members.length}</p>
                    )}
                  </TableCell>
                  <TableCell>{course?.maxMembers}</TableCell>
                  <TableCell>{course?.createdBy}</TableCell>
                  <TableCell>{course?.updatedBy}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleEdit(course)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDelete(course?._id)}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog for Adding/Editing Course */}
        <Dialog open={open} className="poppins " onClose={handleClose}>
          <DialogTitle>{editing ? "Edit Course" : "Add Course"}</DialogTitle>
          <DialogContent className="grid grid-cols-2 gap-2 p-2">
            <DatePicker
              label="Start Date"
              value={formData.startDate}
              onChange={(newValue) => {handleChange("startDate", newValue)}}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <TimePicker
              label="Start Time"
              value={formData.startTime}
              onChange={(newValue) => {handleChange("startTime", newValue)}}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <DatePicker
              label="End Date"
              value={formData.endDate}
              onChange={(newValue) => handleChange("endDate", newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <TimePicker
              label="End Time"
              value={formData.endTime}
              onChange={(newValue) => handleChange("endTime", newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Max Members"
              name="maxMembers"
              type="number"
              value={formData.maxMembers}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default Coursmanager;
