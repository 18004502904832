import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";
import TextEditor from "./components/TextEditor";

const TarifsManager = () => {
  const [tarifs, setTarifs] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [valueFromChild, setValueFromChild] = useState("");
  const [edit, setEdit] = useState("");

  const handleValueChange = (newValue) => {
    setValueFromChild(newValue);
    setDescription(newValue);
  };

  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setUser(response.data);
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  const fetchTarifs = async () => {
    try {
      const response = await makeRequest.get("/tarifs");
      setTarifs(response.data);
    } catch (error) {
      console.error("Error fetching tarifs:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      fetchTarifs();
    }
  }, [isAuthorized]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingIndex !== null) {
        // Update Tarif
        const updatedTarif = { title, description };
        await makeRequest.patch(
          `/tarifs/${tarifs[editingIndex]._id}`,
          updatedTarif
        );
        const updatedTarifs = tarifs.map((tarif, index) =>
          index === editingIndex ? updatedTarif : tarif
        );
        setTarifs(updatedTarifs);
        setEditingIndex(null);
      } else {
        // Create Tarif
        const newTarif = { title, description };
        const response = await makeRequest.post("/tarifs", newTarif);
        setTarifs([...tarifs, response.data]);
      }
      setTitle("");
      setDescription("");
    } catch (error) {
      console.error("Error saving tarif:", error);
    }
  };

  const handleEditTarif = (index) => {
    setEditingIndex(index);
    setTitle(tarifs[index].title);
    setDescription(tarifs[index].description);
    setEdit(tarifs[index].description);
  };

  const handleDeleteTarif = async (index) => {
    try {
      await makeRequest.delete(`/tarifs/${tarifs[index]._id}`);
      const updatedTarifs = tarifs.filter((_, i) => i !== index);
      setTarifs(updatedTarifs);
    } catch (error) {
      console.error("Error deleting tarif:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setTitle("");
    setDescription("");
    setEdit("");
  };

  if (!isAuthorized) {
    return (
      <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
        <Title word={"User Access"}></Title>
        <Title word={"Only"}></Title>
      </div>
    );
  }

  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="Tarifs Manager" />
      </div>
      <div className="flex flex-col items-center gap-4">
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <div className="bg-white">
          <TextEditor
            onValueChange={handleValueChange}
            edit={edit}
          ></TextEditor>
        </div>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {editingIndex !== null ? "Update Tarif" : "Add Tarif"}
        </Button>
        {editingIndex !== null && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelEdit}
          >
            Cancel
          </Button>
        )}
      </div>
      <List>
        {tarifs.map((tarif, index) => (
          <ListItem
            key={index}
            className="border-2 p-4 bg-white border-black rounded-xl"
          >
            <div className="flex flex-col gap-4 ">
              <p>{tarif?.title}</p>
              <div className="flex justify-center items-center ql-snow">
                <p className="px-8 ql-editor">
                  <div
                    dangerouslySetInnerHTML={{ __html: tarif.description }}
                  ></div>
                </p>
              </div>
            </div>

            <IconButton onClick={() => handleEditTarif(index)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDeleteTarif(index)}>
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default TarifsManager;
