import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";

const Nav = () => {
  let [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("/Home");
  const [isVisible, setIsVisible] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, page) => {
    setValue(page);
    navigate(page);
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight - 200) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <div className=" w-full fixed top-0 lg:min-h-24 sm:min-h-0 left-0 z-30 fade-in">
      <div className=" absolute right-4 z-50 top-8 w-12 h-12 bg-white sm-shadow rounded-full cursor-pointer sm:block md:hidden">
        <button
          className={`navbar-toggle   border-2 border-[#111] ${
            open ? "open" : ""
          } absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]`}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span className="toggle-icon rounded-full"></span>
          <span className="toggle-icon rounded-full"></span>
          <span className="toggle-icon rounded-full"></span>
        </button>
      </div>

      <div
        className={`${
          isVisible && location.pathname === "/Home"
            ? "bg-gradient-to-b from-[#111] to-transparent py-8"
            : "bg-gradient-to-b from-[#111] to-[#111] py-6"
        } duration-1000 md:flex sm:hidden items-center justify-between relative   md:px-10 px-7`}
      >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs "
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "white", // Custom color for the indicator
              },
            }}
          >
            <Tab
              value="/Home"
              label={<p className=" text-3xl title text-white ">Accueil</p>}
            />

            <Tab
              value="/Cours"
              label={<p className=" text-3xl title text-white">Cours</p>}
            />
            <Tab
              value="/Tarifs"
              label={<p className=" text-3xl title text-white">Tarifs</p>}
            />
            <Tab
              value="/Photos"
              label={<p className=" text-3xl title text-white">Photos</p>}
            />
            <Tab
              value="/About"
              label={<p className=" text-3xl title text-white">À propos</p>}
            />
            <Tab
              value="/FAQ"
              label={<p className=" text-3xl title text-white">FAQ</p>}
            />
          </Tabs>
        </Box>
      </div>

      <div className="absolute md:hidden top-1/2 left-1/2 translate-x-[-50%] translate-y-[-30%]"></div>

      <div
        className={`sm:flex  md:hidden ${
          !open ? "translate-x-[-100%]" : "translate-x-0"
        }  duration-300 items-center absolute w-screen h-screen top-0 py-24 z-40 justify-center bg-[#111]  md:px-10 `}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
          aria-label="wrapped label tabs "
          sx={{
            width: "300px",
            "& .MuiTabs-indicator": {
              backgroundColor: "white", // Custom color for the indicator
              right: "auto", // Position the indicator on the right side
              left: 20,
            },
          }}
        >
          <Tab
            value="/Home"
            label={<p className=" text-3xl title text-white ">Accueil</p>}
          />

          <Tab
            value="/Cours"
            label={<p className=" text-3xl title text-white">Cours</p>}
          />
          <Tab
            value="/Tarifs"
            label={<p className=" text-3xl title text-white">Tarifs</p>}
          />
          <Tab
            value="/Photos"
            label={<p className=" text-3xl title text-white">Photos</p>}
          />
          <Tab
            value="/About"
            label={<p className=" text-3xl title text-white">À propos</p>}
          />
          <Tab
            value="/FAQ"
            label={<p className=" text-3xl title text-white">FAQ</p>}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default Nav;
