import React, { useState, useEffect } from "react";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";
import TextEditor from "./components/TextEditor";

function ConfigsManager() {
  const [passwordConfig, setPasswordConfig] = useState("");
  const [missionConfig, setMissionConfig] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleValueChange = (newValue) => {
    setMissionConfig(newValue);
  };

  useEffect(() => {
    fetchConfig("password", setPasswordConfig);
    fetchConfig("mission", setMissionConfig);
  }, []);

  const fetchConfig = async (name, setConfig) => {
    try {
      const response = await makeRequest.get(`/configs/${name}`);
      setConfig(response.data.value);
    } catch (error) {
      console.error(`Error fetching ${name} config:`, error);
    }
  };

  const handleUpdateConfig = async (name, value) => {
    try {
      await makeRequest.put(`/configs/${name}`, { value });
      setSnackbarMessage(`${name} updated successfully`);
      setSnackbarSeverity("success");
    } catch (error) {
      console.error(`Error updating ${name} config:`, error);
      setSnackbarMessage(`Error updating ${name}`);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="my-16">
        <Title word="Configs Manager" />
      </div>
      <div className="flex flex-col gap-4">
        <TextField
          label="Password Config"
          type="password"
          value={passwordConfig}
          onChange={(e) => setPasswordConfig(e.target.value)}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUpdateConfig("password", passwordConfig)}
        >
          Update Password
        </Button>

        <TextEditor
          onValueChange={handleValueChange}
          edit={missionConfig}
        ></TextEditor>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUpdateConfig("mission", missionConfig)}
        >
          Update Mission
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ConfigsManager;
