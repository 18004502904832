import React from "react";
import { Box } from "@mui/material";
import TrainerForm from "./components/TrainerForm";
import TrainerList from "./components/TrainerList";
import Title from "../components/Title";
import TrainerOrder from "./components/TrainerOrder";

const TrainerManager = () => {
  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="Create Trainer" />
      </div>
      <TrainerForm />
      <div className="my-16">
        <Title word="Trainers Order" />
      </div>
      <TrainerOrder />
      <div className="my-16">
        <Title word="Trainers" />
      </div>
      <TrainerList />
    </div>
  );
};

export default TrainerManager;
