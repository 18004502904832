import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";
import TextEditor from "./components/TextEditor";

function FAQManager() {
  const [faqs, setFaqs] = useState([]);
  const [newFaq, setNewFaq] = useState({ question: "", answer: "" });
  const [editFaq, setEditFaq] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [valueFromChild, setValueFromChild] = useState("");
  const [edit, setEdit] = useState("");

  const handleValueChange = (newValue) => {
    setValueFromChild(newValue);
    setNewFaq({ ...newFaq, answer: newValue });
  };

  // Fetch Current User
  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setUser(response.data);
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  const fetchFaqs = async () => {
    try {
      const response = await makeRequest.get("/faqs");
      setFaqs(response.data);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isAuthorized) fetchFaqs();
  }, [isAuthorized]);

  // Fetch all FAQs

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editFaq) {
        // Update FAQ
        await makeRequest.put(`/faqs/${editFaq._id}`, newFaq);
      } else {
        // Create FAQ
        await makeRequest.post("/faqs", newFaq);
      }
      setNewFaq({ question: "", answer: "" });
      setEditFaq(null);
      fetchFaqs();
    } catch (error) {
      console.error("Error saving FAQ:", error);
    }
  };

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      await makeRequest.delete(`/${id}`);
      fetchFaqs();
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  // Handle Edit
  const handleEdit = (faq) => {
    setNewFaq(faq);
    setEditFaq(faq);
    setEdit(faq?.answer);
  };

  if (!isAuthorized) {
    return (
      <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
        <Title word={"User Access"}></Title>
        <Title word={"Only"}></Title>
      </div>
    );
  }

  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="FAQ Manager" />
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded p-8 flex flex-col gap-4"
        style={{ marginBottom: "20px" }}
      >
        <TextField
          label="Question"
          value={newFaq.question}
          onChange={(e) => setNewFaq({ ...newFaq, question: e.target.value })}
          required
          style={{ marginRight: "10px" }}
        />
        <div className="bg-white">
          <TextEditor
            onValueChange={handleValueChange}
            edit={edit}
          ></TextEditor>
        </div>

        <Button type="submit" variant="contained" color="primary">
          {editFaq ? "Update FAQ" : "Add FAQ"}
        </Button>
      </form>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faqs?.map((faq) => (
              <TableRow key={faq?._id}>
                <TableCell>{faq?.question}</TableCell>
                <TableCell>
                  <div className=" ql-snow">
                    <p className="px-8 ql-editor">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: faq?.answer,
                        }}
                      ></div>
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "10px" }}
                    onClick={() => handleEdit(faq)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(faq?._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default FAQManager;
