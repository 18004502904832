import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const ClientRegistrations = () => {
  const { id } = useParams();
  const [registrations, setRegistrations] = useState([]);
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Fetch Current User
  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setUser(response.data);
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isAuthorized) fetchRegistrations();
  }, [isAuthorized, id]);

  const fetchRegistrations = async () => {
    try {
      const response = await makeRequest.get(
        `/registrations/client-registrations/${id}`
      );
      setRegistrations(response.data);
    } catch (error) {
      console.error("Error fetching registrations:", error.message);
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filteredRegistrations = registrations.filter((registration) => {
    if (!startDate && !endDate) return true;
    const registrationDate = new Date(registration?.coursId?.start);
    if (isNaN(registrationDate)) return false; // Handle invalid date
    const start = startDate ? new Date(startDate) : new Date("1970-01-01");
    const end = endDate ? new Date(endDate) : new Date("9999-12-31");
    return registrationDate >= start && registrationDate <= end;
  });

  if (!isAuthorized) {
    return (
      <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
        <Title word={"User Access"}></Title>
        <Title word={"Only"}></Title>
      </div>
    );
  }

  return (
    <Box className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="Client Registrations" />
      </div>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mb: 4 }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mb: 4 }}
      />
      <h1 className="excelFont text-5xl">
        {registrations?.length > 0 &&
          registrations[0].firstname + " " + registrations[0].lastname}
      </h1>
      {filteredRegistrations?.length > 0 ? (
        <TableContainer
          component={Paper}
          className="poppins"
          sx={{ mt: 4, width: 300 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cours Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRegistrations?.map((registration) => (
                <TableRow key={registration?._id}>
                  <TableCell>
                    {format(
                      new Date(registration?.coursId?.start),
                      "EEE d MMMM yyyy",
                      { locale: fr }
                    ).toUpperCase()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" sx={{ mt: 4 }}>
          No registrations found.
        </Typography>
      )}
    </Box>
  );
};

export default ClientRegistrations;
