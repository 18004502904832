import React from "react";
import { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";

function Photos() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonStates, setButtonStates] = useState(
    Array(images.length).fill(false)
  );
  const [open, setOpen] = useState(false);
  const [imgDescs, setImgDescs] = useState(Array(images.length).fill(""));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    fetchImages();
    fetchUser();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleResponse = (msg, variant) => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(variant);
    setSnackbarOpen(true);
  };

  async function postImage({ image, description }) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("description", description);

    const result = await makeRequest.post("/images", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return result.data;
  }

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    for (let i = 0; i < files?.length; i++) {
      // @ts-ignore
      const result = await postImage({
        image: files[i],
        description,
      })
        // @ts-ignore
        .then((response) => {
          handleResponse(
            i + 1 + "/" + files.length + " successfully uploaded.",
            "success"
          );
        })
        .catch((error) => {
          handleResponse(
            i + 1 + "/" + files.length + " error uploading.",
            "error"
          );
          console.error("Error posting image:", error);
        });
    }
    setLoading(false);
  };

  const fileSelected = (event) => {
    const files = event.target.files;
    setFiles(files);
  };
  const fetchImages = async () => {
    await makeRequest
      .get("/images/gallery")
      .then((response) => {
        setImages(response.data);
        setButtonStates(Array(response.data.length).fill(false));
        const newImgDesc = [...imgDescs];
        for (let i = 0; i < response.data.length; i++) {
          newImgDesc[i] = response.data[i].description;
        }
        setImgDescs(newImgDesc);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const breakpointColumnsObj = {
    default: 3, // Number of columns by default
    1100: 2, // Number of columns at screen width 1100px and above
    700: 1, // Number of columns at screen width 700px and above
  };

  //Admin

  const toggleButtonState = (index) => {
    const newButtonStates = [...buttonStates];
    newButtonStates[index] = !newButtonStates[index];
    setButtonStates(newButtonStates);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async (itemToDelete) => {
    // Ensure that there is an item selected to delete
    try {
      // Send a delete request to your API or backend
      await makeRequest
        .delete("/images/" + itemToDelete._id)
        // @ts-ignore
        .then((response) => {
          handleResponse("Image Deleted", "success");
          setList((prevArray) => [...prevArray, itemToDelete._id]);
        })
        .catch((error) => {
          console.error("Error deleting img:", error);
          handleResponse("Error Deleting Image", "error");
        });
    } catch (error) {
      console.error("Error while deleting item:", error);
    }
  };

  const handleUpdateDesc = async (index, itemToUpdate) => {
    // Ensure that there is an item selected to delete
    try {
      // Send a delete request to your API or backend
      await makeRequest
        .patch("/images/" + itemToUpdate._id, {
          description: images[index].description,
        })
        // @ts-ignore
        .then((response) => {
          handleResponse("Description Updated", "success");
        })
        .catch((error) => {
          console.error("Error Updating Description:", error);
          handleResponse("Error Updating Description", "error");
        });
    } catch (error) {
      console.error("Error while Updating Description:", error);
    }
  };

  const handleTextField = (index, e) => {
    const updatedImages = [...images]; // Create a copy of the images array
    updatedImages[index].description = e.target.value; // Update the description in the copied array
    setImages(updatedImages); // Update the state with the new array
  };

  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="mt-16">
        <Title word="Gallerie" />
      </div>
      <div>
        <div className=" flex flex-col items-center">
          {isAuthorized && (
            <div className="bg-white my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
              <div className="relative text-center w-fit sm:mb-8 lg:mb-12 text-4xl font-extrabold stats-font p-2">
                Manage Photos
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-black "></span>
              </div>
              <div className="flex justify-center sm:flex-col ">
                <form
                  onSubmit={submit}
                  className="flex flex-col justify-evenly gap-2"
                >
                  <input
                    onChange={fileSelected}
                    type="file"
                    accept="image/*"
                    multiple
                  ></input>

                  <Button
                    component="label"
                    variant="contained"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress sx={{ color: "white" }} />
                      ) : (
                        <CloudUploadIcon />
                      )
                    }
                  >
                    <button type="submit" className="font-semibold">
                      Upload File(s)
                    </button>
                  </Button>
                </form>
              </div>
            </div>
          )}
          <div className="h-fit sm:w-[95%] lg:w-[80%] ">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {images?.map(
                (image, index) =>
                  !list.includes(image._id) && (
                    <div
                      data-content={`${image?.description}`}
                      className={`relative  ${
                        image?.description !== "" ? "photo" : ""
                      }`}
                      key={index}
                    >
                      <div className="w-full h-full absolute ">
                        {isAuthorized && (
                          <div className="absolute top-0 right-0 p-2 z-10 transition-all cursor-pointer rounded-lg">
                            {!buttonStates[index] ? (
                              <Fab
                                aria-label="add"
                                onClick={() => toggleButtonState(index)}
                                className="zoom"
                              >
                                <DeleteIcon
                                  fontSize="large"
                                  className="text-black hover:text-red-600 "
                                  sx={{ transition: "100ms" }}
                                ></DeleteIcon>
                              </Fab>
                            ) : (
                              <div className="flex gap-2">
                                <Fab
                                  color="success"
                                  onClick={() => handleDeleteClick(image)}
                                  className="zoom"
                                >
                                  <CheckIcon fontSize="large" />
                                </Fab>
                                <Fab
                                  color="error"
                                  onClick={() => toggleButtonState(index)}
                                  className="zoom"
                                >
                                  <CloseIcon fontSize="large" />
                                </Fab>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div
                        className="grid-item sm-shadow rounded-lg overflow-hidden flex justify-center"
                        key={image?.name}
                      >
                        <img
                          className="h-auto max-w-full hover:scale-[110%] transition-all duration-500 motion-reduce:transition-none masonry-item  cursor-pointer"
                          src={image?.url}
                          alt=""
                        />
                      </div>
                    </div>
                  )
              )}
            </Masonry>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Photos;
