import React from "react";
import Pricing from "../components/Pricing";

function Forfaits() {
  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <div className="mt-44 mb-24">
        <Pricing></Pricing>
      </div>
      <div className=""></div>
    </div>
  );
}

export default Forfaits;
