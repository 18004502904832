import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Paper, Button } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeRequest } from "../../makeRequest";

const TrainerOrder = () => {
  const [trainers, setTrainers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchTrainers = async () => {
      try {
        const res = await makeRequest.get("/trainers");
        setTrainers(res.data.sort((a, b) => a.order - b.order));
      } catch (error) {
        console.error("Error fetching trainers:", error);
      }
    };
    fetchTrainers();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedTrainers = [...trainers];
    const [movedItem] = updatedTrainers.splice(result.source.index, 1);
    updatedTrainers.splice(result.destination.index, 0, movedItem);

    updatedTrainers.forEach((trainer, index) => {
      trainer.order = index + 1;
    });

    setTrainers(updatedTrainers);
  };

  const saveOrder = async () => {
    setIsSaving(true);
    try {
      await Promise.all(
        trainers.map((trainer) =>
          makeRequest.patch(`/trainers/${trainer._id}`, {
            order: trainer.order,
          })
        )
      );
      alert("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
    }
    setIsSaving(false);
  };

  return (
    <Paper sx={{ padding: 2, maxWidth: 400, margin: "auto" }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        {trainers.length > 0 && ( // Ensure trainers exist before rendering
          <Droppable droppableId="trainers">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {trainers.map((trainer, index) => (
                  <Draggable
                    key={trainer._id}
                    draggableId={trainer._id}
                    index={index}
                  >
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          border: "1px solid #ddd",
                          marginBottom: 1,
                          borderRadius: 1,
                          backgroundColor: "white", // Ensure it's visible
                          display: "flex", // Keep flex layout
                          alignItems: "center", // Ensure content alignment
                          minHeight: 50, // Prevent collapsing
                        }}
                      >
                        <ListItemText
                          primary={`${trainer.firstname} ${trainer.lastname}`}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        )}
      </DragDropContext>
      <Button
        variant="contained"
        color="primary"
        onClick={saveOrder}
        fullWidth
        disabled={isSaving}
        sx={{ marginTop: 2 }}
      >
        {isSaving ? "Saving..." : "Save Order"}
      </Button>
    </Paper>
  );
};

export default TrainerOrder;
